import React from "react";

const JobList = ({ list }) => {
	if (!list) {
		return null;
	}

	return (
		<section className="pretender-section" id="jobs">
			<div className="container">
				<h1 className="viewport move-effect">entiteer werden? Wen wir suchen.</h1>
				<div className="content-holder">
					<div className="info-block viewport move-effect">
						<p>Wir sind ein Team von Tech-Enthusiasten: entiteers verbindet der Drang, die digitale Zukunft mitzugestalten. Dafür brauchen wir Dich und Deine Expertise!</p>
					</div>
					<ul className="workers-list viewport">
						{list.map((job) => {
							return <li key={job.node.id} className="move-effect"><a href={job.node.path}>{job.node.title}</a></li>;
						})}
					</ul>
					<div className="text-block viewport move-effect">
						<p>Nichts Passendes dabei? Schicke uns gerne trotzdem Deine Unterlagen: Wir freuen uns auf neue, spannende Persönlichkeiten in unserem Team. Dein Ansprechpartner ist Stefan Luboschik.</p>
					</div>
					<div className="btn-holder viewport move-effect">
						<a href="mailto:&#119;&#111;&#114;&#107;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;" className="btn btn-decor"><span>&#119;&#111;&#114;&#107;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;</span></a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default JobList;
