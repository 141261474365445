import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import "../../static/css/main.css";
import JobList from "../components/jobList";

class Index extends React.Component {
	render() {
		const { data } = this.props;

		return (
			<div id="wrapper">
				<Helmet>
					<meta charset="utf-8" />
					<meta httpEquiv="x-ua-compatible" content="ie=edge" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
					<title>entity</title>
					<script src="js/jquery3.3.1-jquery.main.js" />
					<script src="https://www.google.com/recaptcha/api.js?render=6LeFAqEUAAAAAOBIGFgU67742Rfbw83Yb-nM2_AJ" />
				</Helmet>
				<header id="header">
					<div className="container-fluid">
						<strong className="logo">
							<a href="#">
								<img src="images/logo.svg" width="94" alt="Entity" />
							</a>
						</strong>
						<a className="nav-opener" href="#"><span /></a>
						<div className="drop">
							<nav className="nav">
								<ul id="menu">
									<li><a className="anchor-link" href="#services">services</a></li>
									<li><a className="anchor-link" href="#cases">cases</a></li>
									<li><a className="anchor-link" href="#ecosystem">ecosystem</a></li>
									<li><a className="anchor-link" href="#team">team</a></li>
									<li><a className="anchor-link" href="#jobs">jobs</a></li>
									<li><a className="anchor-link" href="#contact">kontakt</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</header>
				<main id="main">
					<div className="container container-lg">
						<ul className="breadcrumbs viewport move-effect" />
					</div>
					<strong className="logo hide">entity</strong>
					<section className="entity-section viewport">
						<div className="section-inner" id="section-inner">
							<div className="sections-holder">
								<div className="about-section">
									<div className="container">
										<h2 className="h1 viewport move-effect">Wir sind entity.</h2>
										<div className="text-block viewport move-effect">
											<div className="info-block">
												<p>Wir realisieren disruptive Geschäftsmodelle: mit digitalen Business-Plattformen in Enterprise-Qualität.</p>
											</div>
											<strong className="title">Wir suchen die Herausforderung.</strong>
											<p>Wenn Standardsysteme an Grenzen stoßen, werden wir aktiv: für eine passgenaue Webplattform, die sich schnell und effizient Ihrer Vision anpasst.</p>
											<strong className="title">Wir übernehmen Verantwortung.</strong>
											<p>Wir sind Ihr Tech-Partner über den ganzen Entwicklungsprozess: von der Produktkonzeption bis zum laufenden Betrieb.</p>
											<strong className="title">Mit uns werden visionäre Ideen Wirklichkeit.</strong>
											<p>Wir bieten ein agiles Angebot, das passgenau mit dem Unternehmen wächst.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="advantages-section" id="services">
						<div className="container">
							<h1 className="viewport move-effect">Wir bieten IT-Services, die mitwachsen.</h1>
							<div className="info-block viewport move-effect">
								<p>Unser agiles Angebot liefert für jede Wachstumsphase eines Unternehmens die passgenaue IT-Leistung.</p>
							</div>
							<ul className="advantages-list same-height-block viewport">
								<li className="open-close move-effect">
									<div className="info-holder">
										<div className="icon-block">
											<img src="images/project.svg" width="76" alt="description" />
										</div>
										<div className="text-block">
											<h2>entity project</h2>
											<p>Software-Pakete individuell entwickelt</p>
											<a className="opener" href="#">&nbsp;</a>
										</div>
									</div>
									<div className="slide">
										<strong className="title">Kernleistungen</strong>
										<p>Effiziente Entwicklung spezifischer Arbeitspakete</p>
										<strong className="title">Ergebnis</strong>
										<p>MVP in time, in scope, in budget</p>
										<strong className="title">Vorteil</strong>
										<p>Maximale Planungssicherheit und Kostenkontrolle</p>
										<strong className="title">Ideal für...</strong>
										<p>Early stages des Unternehmens</p>
									</div>
								</li>
								<li className="open-close move-effect">
									<div className="info-holder">
										<div className="icon-block">
											<img src="images/team.svg" width="110" alt="description" />
										</div>
										<div className="text-block">
											<h2>entity team</h2>
											<p>IT-Entwicklungsprozesse professionell organisiert</p>
											<a className="opener" href="#">&nbsp;</a>
										</div>
									</div>
									<div className="slide">
										<strong className="title">Kernleistungen</strong>
										<p>Management des gesamten IT Entwicklungsprozesses und Bereitstellen eines IT-Teams</p>
										<strong className="title">Ergebnis</strong>
										<p>Stetige Weiterentwicklung und Optimierung der Software in kurzen Release-Zyklen</p>
										<strong className="title">Vorteil</strong>
										<p>Moderne, nachhaltige Software-Entwicklung im agilen Prozess</p>
										<strong className="title">Ideal für …</strong>
										<p>wachsende Unternehmen ohne eigene IT-Unit</p>
									</div>
								</li>
								<li className="open-close move-effect">
									<div className="info-holder">
										<div className="icon-block">
											<img src="images/unit.svg" width="68" alt="description" />
										</div>
										<div className="text-block">
											<h2>entity unit</h2>
											<p>Interne Entwicklungsabteilung nachhaltig aufgebaut</p>
											<a className="opener" href="#">&nbsp;</a>
										</div>
									</div>
									<div className="slide">
										<strong className="title">Kernleistungen</strong>
										<p>Aufbau einer eigenständigen internen Entwicklungsabteilung</p>
										<strong className="title">Ergebnis</strong>
										<p>Funktionale IT-Unit gemäß des entity ecosystems</p>
										<strong className="title">Vorteil</strong>
										<p>Nachhaltige, effiziente interne Strukturen für langfristige Stabilität und Unabhängigkeit von externen Anbietern</p>
										<strong className="title">Ideal für...</strong>
										<p>Unternehmen in der Reifephase</p>
									</div>
								</li>
							</ul>
							<div className="btn-holder viewport move-effect">
								<a href="/services" className="btn btn-decor">
									<span>Services vergleichen</span>
								</a>
							</div>
						</div>
					</section>
					<section className="contact-section viewport">
						<div className="container">
							<div className="img-block move-effect">
								<div className="icon-block">
									<img src="images/communication.svg" width="125" alt="description" />
								</div>
							</div>
							<div className="text-block move-effect">
								<h1>Vision verwirklichen? <span>Sprechen Sie mit uns!</span></h1>
								<a href="#contact" className="btn anchor-link">Kontakt aufnehmen</a>
							</div>
						</div>
					</section>
					<section className="clients-section" id="cases">
						<div className="container">
							<h1 className="viewport move-effect">Wir entwickeln Lösungen für jede Branche.</h1>
							<div className="info-block viewport move-effect">
								<p>Wir glauben an das Neue. Deshalb realisieren wir technologisch innovative Geschäftsideen — unabhängig von der Branche.</p>
							</div>
							<div className="clients-holder">
								<ul className="clients-list viewport move-effect">
									<li>
										<a href="/cases/realbest">
											<strong className="work-detail">ONLINE-MARKTPLATZ</strong>
											<img className="visible" src="images/realbest.svg" alt="realbest" />
											<img className="hidden" src="images/realbest-color.svg" alt="realbest" />
											<strong className="industry">IMMOBILIEN</strong>
										</a>
									</li>
									<li>
										<a href="/cases/nzz/">
											<strong className="work-detail">ONLINE COACH</strong>
											<img className="visible" src="images/neue-zurcher-zeitung.svg" alt="Neue Zurcher Zeitung" />
											<img className="hidden" src="images/neue-zurcher-zeitung-color.svg" alt="Neue Zurcher Zeitung" />
											<strong className="industry">HEALTH</strong>
										</a>
									</li>
									<li>
										<a href="/cases/total">
											<strong className="work-detail">TANKSTELLENPARTNER PORTAL</strong>
											<img className="visible" src="images/total.svg" alt="Total" />
											<img className="hidden" src="images/total-color.svg" alt="Total" />
											<strong className="industry">ENERGIE</strong>
										</a>
									</li>
									<li>
										<a href="/cases/wirkaufens/">
											<strong className="work-detail">PLATTFORM ZUR PROZESSSTEUERUNG</strong>
											<img className="visible" src="images/wirkaufens.png" alt="Wirkaufens" />
											<img className="hidden" src="images/wirkaufens-color.png" alt="Wirkaufens" />
											<strong className="industry">E-COMMERCE</strong>
										</a>
									</li>
									<li>
										<a href="/cases/mdkbb/">
											<strong className="work-detail">FORTBILDUNGSPLATTFORM</strong>
											<img className="visible" src="images/mdk-bb-logo-blue.png" alt="MDK BB" />
											<img className="hidden" src="images/mdk-bb-logo.png" alt="MDK BB" />
											<strong className="industry">HEALTH</strong>
										</a>
									</li>
									<li>
										<a href="/cases/daku/">
											<strong className="work-detail">PRODUKT-KONFIGURATOR</strong>
											<img className="visible" src="images/daku.png" alt="DAKU Fensterba Gmbh" />
											<img className="hidden" src="images/daku-color.png" alt="DAKU Fensterba Gmbh" />
											<strong className="industry">HANDEL</strong>
										</a>
									</li>
								</ul>
								<div className="btn-holder viewport move-effect">
									<a className="btn btn-decor opener" href="#"><span>Mehr laden</span></a>
								</div>
							</div>
						</div>
					</section>
					<section className="reviews-section viewport move-effect">
						<div className="container">
							<div className="reviews-slider">
								<div>
									<blockquote>
										<h2 className="hide">Tech-Partner von Anfang an</h2>
										<p>entity ist von Anfang an unser Tech-Partner, mit dem wir unsere Produktvision realisiert und seit dem Launch 2014 kontinuierlich weiterentwickelt haben. Über die realbestPlattform haben wir bereits Objekte im Wert von 1 Milliarde Euro zur Vermittlung akquiriert.
										</p>
										<cite>
											<span className="img-block">
												<img src="images/img-01.png" alt="Mathias Baumeister" />
											</span>
											<span className="text-block">
												<strong className="name">Mathias Baumeister</strong>
												<span className="position">CEO realbest</span>
												<a href="/cases/realbest" className="btn">Case ansehen</a>
											</span>
										</cite>
									</blockquote>
								</div>
								<div>
									<blockquote>
										<h2 className="hide">Schnell und effizient</h2>
										<p>Uns war klar: Wir brauchen eine digitale Lösung, deutschlandweit Bedarf und Verfügbarkeit zu vermitteln, um Ressourcen effizienter nutzen zu können. In einem erfreulich agilen und kreativen Austauschprozess baute uns entity hierfür in kurzer Zeit eine individuelle und anspruchsvolle Plattform. entity versteht es dabei, komplexe Anforderungen in leicht verständliche technische Schritte aufzuschlüsseln. Ein großer Vorteil, besonders in der Projektabstimmung mit internen und externen Stakeholdern.
										</p>
										<cite>
											<span className="img-block">
												<img src="images/img-marc-vincent-thun.jpg" alt="Marc Vincent Thun" />
											</span>
											<span className="text-block">
												<strong className="name">Marc Vincent Thun</strong>
												<span className="position">Venture Development Manager bei Viessmann</span>
											</span>
										</cite>
									</blockquote>
								</div>
							</div>
						</div>
					</section>
					<section className="process-section" id="ecosystem">
						<div className="container">
							<h1 className="viewport move-effect">Wir denken Tech weiter — mit dem entity ecosystem.</h1>
							<div className="info-block viewport move-effect">
								<div className="viewport-item">
									<div className="icon-block js-animate-block" data-path="/inc/ecosystem.json" />
								</div>
								<p>Das entity ecosystem schafft optimale Voraussetzungen für die Entwicklung nachhaltiger Tech-Produkte. Mit entity unit geben wir dieses Modell an unsere Kunden weiter: damit große Ideen Wirklichkeit werden.</p>
							</div>
							<ul className="process-list">
								<li className="viewport-item move-effect">
									<div className="icon-block">
										<div className="icon-block js-animate-block" data-path="inc/entiteers.json" />
									</div>
									<div className="text-block">
										<h2>Die entiteers</h2>
										<p>Kern des entity ecosystems: unsere Mitarbeitenden, die entiteers. Das entity ecosystem bietet entiteers optimale Produktionsbedingungen für moderne, stabile Tech-Plattformen, die höchsten Anforderungen genügen.</p>
									</div>
								</li>
								<li className="viewport-item move-effect">
									<div className="icon-block">
										<div className="icon-block js-animate-block" data-path="inc/techstack.json" />
									</div>
									<div className="text-block">
										<h2>entity techstack</h2>
										<p>Die entiteers beherrschen sämtliche State of the Art-Frontend-, Backend- und Server-Technologien. entity techstack verzahnt Softwareentwicklungs- und Kommunikations-Tools intelligent miteinander: für die effiziente Entwicklung hochperformativer Webplattformen.</p>
									</div>
								</li>
								<li className="viewport-item move-effect">
									<div className="icon-block">
										<div className="icon-block js-animate-block" data-path="inc/process.json" />
									</div>
									<div className="text-block">
										<h2>entity process</h2>
										<p>Projekte realisieren wir individuell anhand von Best Practice-Methoden: von der Organisation, dem Teambuilding und -führung bis zur Programmierung. So garantieren wir effiziente, agile und transparente Prozesse.</p>
									</div>
								</li>
								<li className="viewport-item move-effect">
									<div className="icon-block">
										<div className="icon-block js-animate-block" data-path="inc/culture.json" />
									</div>
									<div className="text-block">
										<h2>entity culture</h2>
										<p>Wir fördern konsequent eine Kultur des eigenverantwortlichen Handelns in einem Arbeitsumfeld, das Kreativität befeuert: für maximale Motivation, volle Potenzialentfaltung, optimale Leistungen.</p>
									</div>
								</li>
							</ul>
						</div>
					</section>
					<section className="contact-section viewport">
						<div className="container">
							<div className="img-block move-effect">
								<div className="icon-block">
									<img src="images/new-project.svg" width="134" alt="description" />
								</div>
							</div>
							<div className="text-block move-effect">
								<h1>Neues schaffen? <span>Lassen Sie uns anfangen!</span></h1>
								<a href="#contact" className="btn anchor-link">Kontakt aufnehmen</a>
							</div>
						</div>
					</section>
					<section className="team-section" id="team">
						<div className="container">
							<h1 className="viewport move-effect">Unser Team. Die entiteers.</h1>
							<ul className="info-list viewport">
								<li className="move-effect">
									<strong className="title">10</strong>
									<p>hochspezialisierte IT-Talente</p>
								</li>
								<li className="move-effect">
									<strong className="title">13</strong>
									<p>maßgebliche IT-Skills</p>
								</li>
								<li className="move-effect">
									<strong className="title">75 Jahre</strong>
									<p>Tech-Erfahrung und - Know-How insgesamt</p>
								</li>
							</ul>
							<div className="icon-block viewport move-effect">
								<img src="images/people-1.svg" alt="description" />
								<img src="images/people-2.svg" alt="description" />
							</div>
							<h2 className="viewport move-effect">Unser umfassendes IT-Skillset</h2>
							<ul className="skills-list viewport move-effect">
								<li>Backend Development</li>
								<li>Database Engineering</li>
								<li>DevOps</li>
								<li>Digital Consulting</li>
								<li>Frontend/UI Development</li>
								<li>Operation Management</li>
								<li>Process Management</li>
								<li>Project Management</li>
								<li>Quality Assurance Management</li>
								<li>Scrum Master</li>
								<li>System Architecture</li>
								<li>Technology Consulting</li>
								<li>UX Research &amp; Design</li>
							</ul>
						</div>
						<div className="gallery-holder">
							<ul className="gallery-block viewport">
								<li className="move-effect"><div className="img-block" style={{ backgroundImage: "url(images/img-02.png)" }} /></li>
								<li className="move-effect"><div className="img-block" style={{ backgroundImage: "url(images/img-03.png)" }} /></li>
							</ul>
						</div>
					</section>
					<JobList
						list={data ? data.allJobsYaml.edges.sort((edge1, edge2) => {
							return edge1.node.order - edge2.node.order;
						}) : null}
					/>
					<div className="cookie-block viewport move-effect">
						<div className="container">
							<div className="text-block">
								<p>Diese Website nutzt ausschließlich technisch notwendige Cookies, um die bestmögliche Funktionalität bieten zu können.</p>
							</div>
							<div className="btn-holder">
								<a href="/privacyagreement">MEHR INFOS</a>
								<a href="#" className="btn btn-sm">OK</a>
							</div>
						</div>
					</div>
				</main>
				<footer id="footer">
					<div className="bottom-footer">
						<div className="footer-holder">
							<div className="top-footer">
								<div className="container viewport" id="contact">
									<div className="form-block move-effect">
										<h2 className="h1">Vision verwirklichen? <span>Wir sind bereit!</span></h2>
										<h2>Senden Sie uns eine Nachricht</h2>
										<form id="contactForm" method="POST" action="https://formcarry.com/s/fAlN_9DlyWw">
											<input type="text" placeholder="Vorname" name="firstName" />
											<input type="text" placeholder="Name*" name="lastName" required />
											<input type="text" placeholder="Firma" name="company" />
											<input type="email" placeholder="Ihre E-Mailadresse*" name="email" required />
											<textarea placeholder="Ihre Nachricht*" name="message" required />
											<div className="checkbox hide" id="checkboxContainer">
												<label htmlFor="check">
													<input id="check" type="checkbox" name="privacyTermsAccepted" />
													<span className="fake-input" />
													<span className="fake-label">Ich akzeptiere die <a href="/privacyagreement">Datenschutzerklärung</a> dieser Website.</span>
												</label>
											</div>
											<input type="hidden" id="captchaResponse" name="g-recaptcha-response" />
											<button id="contactFormSubmitButton" type="submit" className="btn btn-decor"><span>Senden</span></button>
										</form>
									</div>
									<div className="contact-block move-effect">
										<h2>entity</h2>
										<address>Schlesische Straße 29-30 Aufgang M, 2. OG <br /> 10997 Berlin</address>
										<ul className="info-list">
											<li><a className="tel" href="tel:+4930233264900">+49 30 23 32 649 00</a></li>
											<li><a href="mailto:&#105;&#110;&#102;&#111;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;">&#105;&#110;&#102;&#111;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;</a></li>
										</ul>
										<div className="map-block">
											<img src="images/map.png" srcSet="images/map-2x.png 2x" alt="" width="422" height="422" />
										</div>
										<a href="/impressum">Impressum</a>
									</div>
								</div>
							</div>
							<div className="inner">
								<div className="container">
									<h1><span data-char="v">v</span><span data-char="i">i</span><span data-char="s">s</span><span data-char="i">i</span><span data-char="o">o</span><span data-char="n">n</span><span data-char="s">s</span> <span data-char="t">t</span><span data-char="o">o</span> <span data-char="r">r</span><span data-char="e">e</span><span data-char="a">a</span><span data-char="l">l</span><span data-char="i">i</span><span data-char="t">t</span><span data-char="y">y</span></h1>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}

export default Index;

export const query = graphql`
  query JobListQuery {
    allJobsYaml {
		edges {
			node {
				id
				title
				path
				order
			}
		}
	}
  }
`;
